import React from "react";
import { Container, Row } from "react-bootstrap";
import { pricingBackImage } from "../assets";
import BlogCard from "../components/blogs/BlogCard";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import Head from "../components/common/Head";
import GET_ALL_BLOGS from "../queries/blog/getAllBlogs";
import Query from "../utils/Query";

const Blogs = () => {
  return (
    <div>
      <Head
        title="Blogs | Joraum Solutions Private Limited"
        description="Joraum solutions private limited has provided its expertise for the customers. Here you can find some of the writing published by us."
        canonical="https://joraum.com/blog"
      />
      <BreadcumbHeader
        title="Blog"
        heading="Our Blogs"
        backImage={pricingBackImage}
      />
      <Container className="information">
        <Row>
          <Query query={GET_ALL_BLOGS} sort="DESC">
            {({
              data: {
                blogs: { nodes },
              },
            }) => {
              return nodes.map((entry) => (
                <BlogCard key={entry.slug} data={entry} />
              ));
            }}
          </Query>
        </Row>
      </Container>
    </div>
  );
};

export default Blogs;
