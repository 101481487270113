import React from "react";
import { motion } from "framer-motion";
import { animationVariants } from "../../utils/AnimationMotion";

const Map = () => {
  return (
    <motion.div
      className="contact-map"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.2 }}
      variants={animationVariants}
    >
      <h2 className="text-center mb-4 mt-4">Find us here</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.855931653942!2d78.36079941487738!3d17.466608288031146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93cab9a02ceb%3A0x258d877165aacd38!2sTulip%20Le%20Park!5e0!3m2!1sen!2snp!4v1674472662219!5m2!1sen!2snp"
        title="map"
      ></iframe>
    </motion.div>
  );
};

export default Map;
