import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Secondary = () => {
  return (
    <div className="footer-secondary">
      <Container className="footer-secondary-main">
        <span className="copy-info">
          &copy; Joraum Solutions Pvt. Ltd. All rights reserved.
        </span>
        <div className="right">
          <Link className="link" to="/privacy">
            Privacy Policy
          </Link>
          <Link className="link" to="/terms">
            Terms and Conditions
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default Secondary;
