import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/common/layout";
import About from "./containers/About";
import Blogs from "./containers/Blogs";
import Contact from "./containers/Contact";
import Pricing from "./containers/Pricing";
import Home from "./containers/Home";
import Product from "./containers/Product";
import Blog from "./containers/Blog";
import Privacy from "./containers/Privacy";
import Terms from "./containers/Terms";
import { error404 } from "./assets";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blog/:id" element={<Blog />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route
            path="/*"
            element={
              <div className="error">
                <img
                  src={error404}
                  alt="Page not found"
                  height="512"
                  width="512"
                  className="error-image"
                />
                <p>The requested page is not found.</p>
              </div>
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
