import React from "react";
import { Container } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const BreadcumbHeader = ({ backImage, title, heading }) => {
  return (
    <div
      className="breadcumb"
      style={{
        backgroundImage: `url(${backImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
        backgroundSize: "cover",
      }}
    >
      <Container className="breadcumb-container">
        <div className="breadcumb-container-breadcumb">
          <Link to="/" className="home-link">
            Home
          </Link>
          <FaAngleRight />
          <span className="page-name">{title}</span>
        </div>
        <div className="breadcumb-container-header">
          <h1>{heading}</h1>
        </div>
      </Container>
    </div>
  );
};

export default BreadcumbHeader;
