import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Col } from "react-bootstrap";
import { animationVariants } from "../../utils/AnimationMotion";
import { motion } from "framer-motion";

const FeaturedCard = ({ data, id }) => {
  return (
    <Col lg={4} md={6} xs={12} className="mb-4">
      <Link to={`/product/${id}`}>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
          variants={animationVariants}
          className="featured-card"
        >
          <div className="featured-card-image">
            <img src={data.logo.mediaItemUrl} alt="" />
          </div>
          <span className="featured-card-header">{data.title}</span>
          <p className="featured-card-body">{data.smallDescription}</p>
          <div className="featured-card-link">
            <span>Learn More</span>
            <AiOutlineArrowRight />
          </div>
        </motion.div>
      </Link>
    </Col>
  );
};

export default FeaturedCard;
