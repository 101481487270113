import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import { error404, pricingBackImage } from "../assets";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import LatestBlogs from "../components/singleNews/LatestBlogs";
import GET_BLOG from "../queries/blog/getABlog";
import { animationVariants } from "../utils/AnimationMotion";
import Query from "../utils/Query";
import { motion } from "framer-motion";
import Head from "../components/common/Head";
import extractTextOnly from "../utils/extractTextOnly";

const Blog = () => {
  const slug = useParams().id;
  return (
    <div>
      <BreadcumbHeader
        title="Blog"
        heading="Blog"
        backImage={pricingBackImage}
      />
      <Query query={GET_BLOG} slug={slug}>
        {({ data: { blog } }) => {
          return blog ? (
            <Container>
              <Head
                title={`${blog.blogs.title} | JSPL`}
                canonical={`https://joraum.com/blog/${slug}`}
                description={extractTextOnly(blog.blogs.content).substring(
                  0,
                  150
                )}
              />
              <motion.div
                className="news"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.2 }}
                variants={animationVariants}
              >
                <div className="news-header">
                  <h1 className="title">{blog.blogs.title}</h1>
                  <Moment format="DD MMM YYYY" className="date">
                    {blog.date}
                  </Moment>
                </div>
                <Row>
                  <Col md={8}>
                    <div className="news-body">
                      <img src={blog.blogs.featuredImages.sourceUrl} alt="" />
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: blog.blogs.content }}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <LatestBlogs newsId={slug} />
                  </Col>
                </Row>
              </motion.div>
            </Container>
          ) : (
            <div className="error">
              <img
                src={error404}
                alt="Page not found"
                height="512"
                width="512"
                className="error-image"
              />
              <p>The requested blog is not found.</p>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default Blog;
