import { gql } from "@apollo/client";

const GET_ABOUT_US = gql`
  query getAboutUs($id: ID!) {
    page(id: $id, idType: ID) {
      about {
        content
        featuredImage {
          mediaItemUrl
        }
      }
    }
  }
`;

export default GET_ABOUT_US;
