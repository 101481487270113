import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdOutlineLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import Query from "../../../../utils/Query";
import GET_ALL_PRODUCT from "../../../../queries/product/getAllProducts";

const MainFooter = () => {
  return (
    <Container className="footer-main">
      <Row>
        <Col md={4}>
          <div className="footer__item">
            <div className="footer__item-heading">
              <h4>Contact</h4>
            </div>
            <div className="footer__item-contact">
              <h4 className="company-name">
                Jor<span className="red">a</span>um solutions private limited
              </h4>
              <span className="location-info">
                <MdOutlineLocationOn />
                &nbsp;Tulip Le Park, Anand Nagar, Kothaguda, Kondapur,
                Hyderabad, Telangana 500084
              </span>
              <span className="phone-no">
                Phone: <a href="tel:+91-79952-93058">+91 79952 93058</a>,{" "}
                <a href="tel:+91-88860-00853">+91 88860 00853</a>
              </span>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className="footer__item">
            <div className="footer__item-heading">
              <h4>About Joraum</h4>
            </div>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col md={3}>
          <div className="footer__item">
            <div className="footer__item-heading">
              <h4>Products</h4>
            </div>
            <ul>
              <Query query={GET_ALL_PRODUCT}>
                {({
                  data: {
                    products: { nodes },
                  },
                }) => {
                  return nodes.map(({ slug, productContent }) => (
                    <li key={slug}>
                      <Link to={`/product/${slug}`}>
                        {productContent.title}
                      </Link>
                    </li>
                  ));
                }}
              </Query>
            </ul>
          </div>
        </Col>
        <Col md={2}>
          <div className="footer__item">
            <div className="footer__item-socials">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/joraum-solutions-pvt-ltd/"
              >
                LinkedIn <FaLinkedin />
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MainFooter;
