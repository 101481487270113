import React from "react";
import { Col } from "react-bootstrap";
import { motion } from "framer-motion";
import { animationVariants } from "../../utils/AnimationMotion";

const MemberCard = ({ data }) => {
  return (
    <Col lg={4} md={6} xs={12} className="mb-4">
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        variants={animationVariants}
        className="members-card"
      >
        <img src={data.image.sourceUrl} alt="" />
        <div className="members-card-information">
          <div className="members-card-information-header">
            <h4>{data.name}</h4>
            <h5>{data.designation}</h5>
          </div>
        </div>
      </motion.div>
    </Col>
  );
};

export default MemberCard;
