import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import GET_ABOUT_US from "../../queries/info/getAboutUs";
import Query from "../../utils/Query";
import { motion } from "framer-motion";
import { animationVariants } from "../../utils/AnimationMotion";

const AboutUs = () => {
  return (
    <motion.div
      className="about-us"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.2 }}
      variants={animationVariants}
    >
      <Query query={GET_ABOUT_US} id="cG9zdDo0Nw==">
        {({
          data: {
            page: {
              about: { content, featuredImage },
            },
          },
        }) => {
          return (
            <Container>
              <Row>
                <Col md={6}>
                  <div className="about-us-image">
                    <img src={featuredImage.mediaItemUrl} alt="" />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="about-us-body">
                    <div className="about-us-body-header">
                      <div className="info">
                        <div className="line"></div>
                        <span>WHAT WE DO</span>
                      </div>
                      <h2>About JSPL</h2>
                    </div>
                    <div className="content">
                      <div dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          );
        }}
      </Query>
    </motion.div>
  );
};

export default AboutUs;
