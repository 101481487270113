import React from "react";
import { Container } from "react-bootstrap";
import { pricingBackImage } from "../assets";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import Head from "../components/common/Head";
import ContactCard from "../components/contact/ContactCard";

const Pricing = () => {
  return (
    <div>
      <Head
        title="Pricing | Joraum Solutions Private Limited"
        description="Joraum solutions private limited has its office in Tulip Le Park, Anand Nagar. You can contact us on: +91 79952 93058, +91 88860 00853 and email us on: jyoshnarani@joraum.com"
        canonical="https://joraum.com/pricing"
      />
      <BreadcumbHeader
        title="Pricing"
        heading="Pricing"
        backImage={pricingBackImage}
      />
      <Container className="pricing-contact">
        <h2 className="text-center">Contact for pricing</h2>
        <ContactCard />
      </Container>
    </div>
  );
};

export default Pricing;
