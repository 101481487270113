import React from "react";
import { Helmet } from "react-helmet-async";

const Head = ({ title, description, canonical = "/" }) => {
  const defaultTitle = "Joraum Solutions Private Limited";
  const defaultDescription =
    "JSPL is a product sales and services company founded by a dynamic entrepreneur Jyoshna Rani with the core belief of customer first to provide the best solutions and services at the best price with the best post sale support.";
  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
      <link rel="canonical" href={canonical} />
      <meta
        name="description"
        content={description ? description : defaultDescription}
      />
      <meta property="og:title" content={title ? title : defaultTitle} />
      <meta
        property="og:description"
        content={description ? description : defaultDescription}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title ? title : defaultTitle} />
      <meta
        name="twitter:description"
        content={description ? description : defaultDescription}
      />
    </Helmet>
  );
};

export default Head;
