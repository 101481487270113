import React from "react";
import { Link, NavLink } from "react-router-dom";
import { logo } from "../../../../../assets";
import NavDropdownItem from "./NavDropdownItem";

const LargeNavbar = ({ productData }) => {
  return (
    <div className="my-navbar-main">
      <div className="logo">
        <Link to="/" className="">
          <img src={logo} className="" alt="" />
        </Link>
      </div>
      <ul className="nav">
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "active nav-links" : "nav-links"
            }
          >
            Home
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? "active nav-links" : "nav-links"
            }
          >
            About us
          </NavLink>
        </li>

        <NavDropdownItem heading="Products" data={productData} />

        <li>
          <NavLink
            to="/pricing"
            className={({ isActive }) =>
              isActive ? "active nav-links" : "nav-links"
            }
          >
            Pricing
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/blog"
            className={({ isActive }) =>
              isActive ? "active nav-links" : "nav-links"
            }
          >
            Blog
          </NavLink>
        </li>

        <li>
          <NavLink
            to="/contact-us"
            className={({ isActive }) =>
              isActive ? "active nav-links" : "nav-links"
            }
          >
            Contact Us
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default LargeNavbar;
