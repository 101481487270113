import { gql } from "@apollo/client";

const GET_TEAM_MEMBERS = gql`
  query getTeamMemebers {
    teams {
      nodes {
        managementTeam {
          name
          designation
          image {
            sourceUrl
          }
        }
      }
    }
  }
`;

export default GET_TEAM_MEMBERS;
