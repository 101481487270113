import { gql } from "@apollo/client";

const GET_FEATURED = gql`
  query getFeatured {
    products {
      nodes {
        slug
        productContent {
          title
          smallDescription
          logo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default GET_FEATURED;
