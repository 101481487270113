import React from "react";
import { FaBookmark } from "react-icons/fa";
import { motion } from "framer-motion";
import { animationVariants } from "../../utils/AnimationMotion";

const ContactCard = () => {
  return (
    <motion.div
      className="contact-card"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.2 }}
      variants={animationVariants}
    >
      <div className="bookmark-icon">
        <FaBookmark />
      </div>
      <h3>
        Jor<span className="red">a</span>um solutions private limited
      </h3>
      <div className="contact-card-address">
        <span>
          Tulip Le Park, Anand Nagar, Kothaguda, Kondapur, Hyderabad, Telangana
          500084
        </span>
      </div>
      <div className="contact-item">
        <span className="contact-item-title">Phone:</span>
        <span className="contact-item-content">
          <a href="tel:+91-79952-93058">+91 79952 93058</a>,{" "}
          <a href="tel:+91-88860-00853">+91 88860 00853</a>
        </span>
      </div>
      <div className="contact-item">
        <span className="contact-item-title">Email:</span>
        <span className="contact-item-content">
          <a href="mailto:jyoshnarani@joraum.com">jyoshnarani@joraum.com</a>
        </span>
      </div>
      <div className="contact-item">
        <span className="contact-item-title">Website:</span>
        <span className="contact-item-content">www.joraum.com</span>
      </div>
    </motion.div>
  );
};

export default ContactCard;
