import React from "react";
import { Col } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { animationVariants } from "../../utils/AnimationMotion";
import extractTextOnly from "../../utils/extractTextOnly";
import { motion } from "framer-motion";

const BlogCard = ({ data }) => {
  var description = extractTextOnly(data.blogs.content).substring(0, 50);
  return (
    <Col lg={3} md={4} className="mb-4">
      <Link to={`/blog/${data.slug}`}>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
          variants={animationVariants}
          className="blog-card"
        >
          <div className="blog-card-image">
            <img src={data.blogs.featuredImages.sourceUrl} alt="" />
          </div>
          <div className="blog-card-body">
            <div className="blog-card-body-header">
              <Moment className="date" format="DD MMM YYYY">
                {data.date}
              </Moment>
              <span className="title">{data.blogs.title}</span>
            </div>
            <p
              className="blog-card-body-content"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className="blog-card-body-link">
              <span>Learn More</span>
              <AiOutlineArrowRight />
            </div>
          </div>
        </motion.div>
      </Link>
    </Col>
  );
};

export default BlogCard;
