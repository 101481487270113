import React from "react";
import { Container } from "react-bootstrap";
import Head from "../components/common/Head";
import Featured from "../components/home/Featured";
import Introduction from "../components/home/Introduction";
import Slider from "../components/home/Slider";

const Home = () => {
  return (
    <div>
      <Head
        title="Home | Joraum Solutions Private Limited"
        canonical="https://joraum.com/"
      />
      <Slider />
      <Container>
        <Introduction />
        <Featured />
      </Container>
    </div>
  );
};

export default Home;
