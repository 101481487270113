import { gql } from "@apollo/client";

const GET_BLOG = gql`
  query getBlog($slug: ID!) {
    blog(id: $slug, idType: SLUG) {
      date
      blogs {
        title
        featuredImages {
          sourceUrl
        }
        content
      }
    }
  }
`;

export default GET_BLOG;
