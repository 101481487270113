import React from "react";
import { NavLink } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa";

const NavDropdownItem = ({ heading, data }) => {
  return (
    <li className="nav-dropdown">
      <div className="nav-dropdown-header">
        <span>{heading}</span>
        <FaAngleDown className="arrow" />
      </div>
      <ul className="menu">
        {data.map(({ value, link }) => (
          <li key={link} className="item">
            <NavLink
              to={link}
              className={({ isActive }) =>
                isActive ? "active nav-links" : "nav-links"
              }
            >
              {value}
            </NavLink>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default NavDropdownItem;
