import React from "react";
import { Row } from "react-bootstrap";
import GET_FEATURED from "../../queries/product/getFeatured";
import Query from "../../utils/Query";
import FeaturedCard from "./FeaturedCard";

const Featured = () => {
  return (
    <div className="home-featured">
      <Row className="justify-content-center">
        <Query query={GET_FEATURED}>
          {({
            data: {
              products: { nodes },
            },
          }) => {
            return nodes.map((data, index) => (
              <FeaturedCard
                data={data.productContent}
                id={data.slug}
                key={index}
              />
            ));
          }}
        </Query>
      </Row>
    </div>
  );
};

export default Featured;
