import React from "react";
import { Container } from "react-bootstrap";
import { pricingBackImage } from "../assets";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import Head from "../components/common/Head";

const Privacy = () => {
  return (
    <div>
      <Head
        title="Privacy Policy | Joraum Solutions Private Limited"
        description=" As part of Joraum, we are dedicated to keeping your data private and
        secure. We are also committed to transparency, so we want you to
        understand the information we may collect from you and why we
        collect it."
        canonical="https://joraum.com/privacy"
      />
      <BreadcumbHeader
        title="Privacy"
        heading="Privacy"
        backImage={pricingBackImage}
      />
      <Container>
        <div className="info-container">
          <p className="body-content">
            As part of Joraum, we are dedicated to keeping your data private and
            secure. We are also committed to transparency, so we want you to
            understand the information we may collect from you and why we
            collect it. The following is a supplement to Joraum's privacy
            commitment.
          </p>
          <p className="body-content">
            The information we collect falls into three categories:
          </p>
          <p className="body-content">
            <span className="sub-header">Purchase Information</span>
            <br />
            When you purchase or use one of our free products, we collect basic
            information provided by you to ensure a record of your purchase and
            entitlements.
          </p>
          <p className="body-content">
            <span className="sub-header">Purchase Information</span>
            <br />
            When you purchase or use one of our free products, we collect basic
            information provided by you to ensure a record of your purchase and
            entitlements.
          </p>
          <p className="body-content">
            <span className="sub-header">Usage Information</span>
            <br />
            We capture information that helps us understand your experience with
            Joraum, so that we can offer you more helpful content to help you
            get better at the software you’re working with. We do use some of
            this information to serve you applicable content through digital
            advertising, email, and web properties.
          </p>
          <p className="body-content">
            <span className="sub-header">Profile Information</span>
            <br />
            We store information you provide to us to maintain a profile
            associated with your Joraum account. We use this information to
            communicate with you, and we never share it with third parties
            unless you explicitly ask us to, helpful content to help you get
            better at the software you’re working with, and you will always have
            the ability to update, remove, or add information as desired.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Privacy;
