import { gql } from "@apollo/client";

const GET_ALL_PRODUCT = gql`
  query getAllProducts {
    products {
      nodes {
        slug
        productContent {
          title
        }
      }
    }
  }
`;

export default GET_ALL_PRODUCT;
