import React from "react";
import { Container, Row } from "react-bootstrap";
import { aboutBackImage } from "../assets";
import AboutUs from "../components/about/AboutUs";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import Head from "../components/common/Head";
import MemberCard from "../components/teams/MemberCard";
import GET_TEAM_MEMBERS from "../queries/teams/getAllTeamMembers";
import Query from "../utils/Query";

const About = () => {
  return (
    <div>
      <Head
        title="About Us | Joraum Solutions Private Limited"
        canonical="https://joraum.com/about"
      />
      <BreadcumbHeader
        title="About Us"
        heading="Company Overview"
        backImage={aboutBackImage}
      />
      <AboutUs />
      <Container className="members">
        <h2>Our Team</h2>
        <p>
          Expertise combined with personal commitment — that's our team's recipe
          for success
        </p>
        <Row>
          <Query query={GET_TEAM_MEMBERS}>
            {({
              data: {
                teams: { nodes },
              },
            }) => {
              return nodes.map(({ managementTeam }, key) => (
                <MemberCard data={managementTeam} key={key} />
              ));
            }}
          </Query>
        </Row>
      </Container>
    </div>
  );
};

export default About;
