import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import GET_A_PRODUCT from "../queries/product/getAProduct";
import Query from "../utils/Query";
import { motion } from "framer-motion";
import { animationVariants } from "../utils/AnimationMotion";
import Head from "../components/common/Head";
import { error404 } from "../assets";

const Product = () => {
  const slug = useParams().id;

  return (
    <Query query={GET_A_PRODUCT} slug={slug}>
      {({ data: { product } }) => {
        return product ? (
          <div className="product">
            <Head
              title={`${product.productContent.title} | Joraum Solutions Private Limited`}
              description={`${product.productContent.smallDescription}`}
              canonical={`https://joraum.com/product/${slug}`}
            />
            <BreadcumbHeader
              backImage={product.productContent.bannerImage.mediaItemUrl}
              title={product.productContent.title}
              heading={product.productContent.title}
            />
            <Container>
              <ul className="product-container">
                {product.productContent.contentDiv.map((entry, key) => (
                  <motion.li
                    className="content-div"
                    key={key}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.2 }}
                    variants={animationVariants}
                  >
                    <div className="content-div-details">
                      <div
                        dangerouslySetInnerHTML={{ __html: entry.details }}
                      />
                    </div>
                    <div className="content-div-image">
                      <img src={entry.image.mediaItemUrl} alt="" />
                    </div>
                  </motion.li>
                ))}
              </ul>
              {product.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: product.content }}
                  className="product-content"
                />
              )}
              <div className="product-images">
                {product.productContent.featueredImages &&
                  product.productContent.featueredImages.map(
                    ({ mediaItemUrl }) => (
                      <motion.div
                        className="product-images-container"
                        key={mediaItemUrl}
                        initial="offscreen"
                        whileInView="onscreen"
                        viewport={{ once: true, amount: 0.2 }}
                        variants={animationVariants}
                      >
                        <img
                          src={mediaItemUrl}
                          alt=""
                          className="image-container"
                        />
                      </motion.div>
                    )
                  )}
              </div>
            </Container>
          </div>
        ) : (
          <div className="error">
            <img
              src={error404}
              alt="Page not found"
              height="512"
              width="512"
              className="error-image"
            />
            <p>The requested product is not found.</p>
          </div>
        );
      }}
    </Query>
  );
};

export default Product;
