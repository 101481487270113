import { gql } from "@apollo/client";

const GET_RECENT_BLOGS = gql`
  query getRecentBlogs($notin: [ID]) {
    blogs(
      where: { orderby: { field: DATE, order: DESC }, notIn: $notin }
      first: 5
    ) {
      nodes {
        slug
        blogs {
          title
        }
      }
    }
  }
`;

export default GET_RECENT_BLOGS;
