import React from "react";
import { motion } from "framer-motion";
import { animationVariants } from "../../utils/AnimationMotion";

const Introduction = () => {
  return (
    <motion.div
      className="home-introduction"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.2 }}
      variants={animationVariants}
    >
      <h2 className="home-introduction-header">About JSPL</h2>
      <div className="blue-line"></div>
      <p className="home-introduction-body">
        JSPL is a product sales and services company founded by a dynamic
        entrepreneur Jyoshna Rani with the core belief of customer first to
        provide the best solutions and services at the best price with the best
        post sale support.
      </p>
    </motion.div>
  );
};

export default Introduction;
