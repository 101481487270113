import { gql } from "@apollo/client";

const GET_A_PRODUCT = gql`
  query getAProduct($slug: ID!) {
    product(id: $slug, idType: SLUG) {
      productContent {
        title
        smallDescription
        contentDiv {
          details
          image {
            mediaItemUrl
          }
        }
        featueredImages {
          mediaItemUrl
        }
        bannerImage {
          mediaItemUrl
        }
      }
      content
    }
  }
`;

export default GET_A_PRODUCT;
