import { gql } from "@apollo/client";

const GET_ALL_BLOGS = gql`
  query getAllBlogs($sort: OrderEnum!) {
    blogs(where: { orderby: { field: DATE, order: $sort } }) {
      nodes {
        slug
        date
        blogs {
          title
          featuredImages {
            sourceUrl
          }
          content
        }
      }
    }
  }
`;

export default GET_ALL_BLOGS;
