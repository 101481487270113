import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion";
import { animationVariants } from "../../utils/AnimationMotion";

const ContactForm = () => {
  const captchaRef = useRef();

  const [submitStatus, setSubmitStatus] = useState("idle");

  const [captcha, setCaptcha] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitStatus("loading");

    let err = false;
    // console.log(form.current);

    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const mobileRegex = /^\d{10}$/;
    if (!name) {
      setNameError("Please provide name");
      err = true;
    }
    if (!email) {
      setEmailError("Please provide an email");
      err = true;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
      err = true;
    }
    if (!mobile) {
      setMobileError("Please provide mobile number");
      err = true;
    } else if (!mobileRegex.test(mobile)) {
      setMobileError("Please provide a valid mobile number");
      err = true;
    }
    if (!err) {
      const data = { name, email, mobile };
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          data,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (result) => {
            setSubmitStatus("success");
            captchaRef.current.reset();
            setCaptcha(false);
            clearForm();
            setTimeout(() => {
              setSubmitStatus("idle");
            }, 3000);
          },
          (error) => {
            console.log(error.text);
            setSubmitStatus("idle");
          }
        );
    } else {
      setSubmitStatus("idle");
    }
  };

  const clearForm = () => {
    setName("");
    setNameError("");
    setEmail("");
    setEmailError("");
    setMobile("");
    setMobileError("");
  };

  const onCaptchaChange = (e) => {
    if (e) {
      setCaptcha(true);
    } else {
      setCaptcha(false);
    }
  };

  return (
    <motion.div
      className="contact-form"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.2 }}
      variants={animationVariants}
    >
      <div className="text-center">
        <h2 className="text-center">Ready to connect?</h2>
        <span className="header-message">
          Thank you for your interest. Please fill out the form below, we will
          get back to you.
        </span>
      </div>
      <form onSubmit={handleSubmit} className="contact-form-main">
        <div className="contact-form-main-item">
          <label htmlFor="name">Full Name</label>
          <input
            id="name"
            type="text"
            value={name}
            placeholder="Enter your name"
            onChange={(e) => {
              setName(e.target.value);
              setNameError("");
            }}
          />
          {nameError && <span>{nameError}</span>}
        </div>
        <div className="contact-form-main-item">
          <label htmlFor="mobile">Mobile Number</label>
          <input
            id="mobile"
            type="text"
            value={mobile}
            placeholder="Enter your mobile number"
            onChange={(e) => {
              setMobile(e.target.value);
              setMobileError("");
            }}
          />
          {mobileError && <span>{mobileError}</span>}
        </div>
        <div className="contact-form-main-item">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="text"
            value={email}
            placeholder="Enter your email"
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError("");
            }}
          />
          {emailError && <span>{emailError}</span>}
        </div>

        <ReCAPTCHA
          sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
          onChange={onCaptchaChange}
          ref={captchaRef}
        />
        <button type="submit" disabled={!captcha || submitStatus === "loading"}>
          {submitStatus === "loading"
            ? "Loading..."
            : submitStatus === "success"
            ? "Thankyou"
            : "Submit"}
        </button>
      </form>
    </motion.div>
  );
};

export default ContactForm;
