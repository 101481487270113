import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import MobileNavbar from "./mobileNav";
import LargeNavbar from "./large";
import { useQuery } from "@apollo/client";
import GET_ALL_PRODUCT from "../../../../queries/product/getAllProducts";

const NavBar = () => {
  const [productData, setProductData] = useState([]);

  const {
    data: productTypes,
    loading: productLoading,
    error: productError,
  } = useQuery(GET_ALL_PRODUCT);

  useEffect(() => {
    if (!productLoading && !productError) {
      const temp = productTypes.products.nodes.map((product) => ({
        value: product.productContent.title,
        link: `/product/${product.slug}`,
      }));
      setProductData(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productTypes]);

  return (
    <nav className="my-navbar">
      <Container>
        {/* For screen greater than 576px */}
        <LargeNavbar productData={productData} />
        {/* For screen less than 576px */}
        <MobileNavbar productData={productData} />
      </Container>
    </nav>
  );
};

export default NavBar;
