import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { contactBackImage } from "../assets";
import BreadcumbHeader from "../components/common/BreadcumbHeader";
import Head from "../components/common/Head";
import ContactCard from "../components/contact/ContactCard";
import ContactForm from "../components/contact/ContactForm";
import Map from "../components/contact/Map";

const Contact = () => {
  return (
    <div>
      <Head
        title="Contact | Joraum Solutions Private Limited"
        description="Joraum solutions private limited has its office in Tulip Le Park, Anand Nagar. You can contact us on: +91 79952 93058, +91 88860 00853 and email us on: jyoshnarani@joraum.com"
        canonical="https://joraum.com/contact-us"
      />
      <BreadcumbHeader
        title="Contact Us"
        heading="Get in touch with our team"
        backImage={contactBackImage}
      />
      <Container>
        <div className="contact">
          <Row>
            <Col md={4}>
              <ContactCard />
            </Col>
            <Col md={4}>
              <ContactForm />
            </Col>
            <Col md={4}>
              <Map />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Contact;
