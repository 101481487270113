import React from "react";
import { MdArrowForward } from "react-icons/md";
import { Link } from "react-router-dom";
import GET_RECENT_BLOGS from "../../queries/blog/getRecentBlogs";
import Query from "../../utils/Query";
import { motion } from "framer-motion";
import { animationVariants } from "../../utils/AnimationMotion";

const LatestBlogs = ({ newsId }) => {
  return (
    <motion.div
      className="news-latest"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.2 }}
      variants={animationVariants}
    >
      <h3>Latest Blogs</h3>
      <ul>
        <Query query={GET_RECENT_BLOGS} notin={newsId}>
          {({
            data: {
              blogs: { nodes: data },
            },
          }) => {
            return data.map(({ slug, blogs }) => (
              <li key={slug}>
                <Link to={`/blog/${slug}`}>
                  <MdArrowForward />
                  <span>{blogs.title}</span>
                </Link>
              </li>
            ));
          }}
        </Query>
      </ul>
    </motion.div>
  );
};

export default LatestBlogs;
